<template>
  <div class="mustBuyGoods flex" @click="$router.push({path:'/home/details',query: {id: goods.goodsId}})">
      <!-- <img v-if="goods%2==0" class="icon" src="/img/icon-xp.png" alt="">
      <img v-if="goods%2==1" class="icon" src="/img/icon-tj.png" alt=""> -->
      <img class="icon" src="/img/icon-rm.png" alt="">
      <!-- <img class="goods-img" :src="goods.pic" alt=""> -->
      <van-image class="goods-img" fit="cover" lazy-load :src="goods.pic" />
      <div class="flex fd-c ai-c mustBuyGoods-r">
          <div class="name line">{{goods.name}}</div>
          <div class="reduction">
              <div class="t1 flex ai-c jc-sb">
                  <div class="t">日常价</div>
                  <div class="t">即将恢复</div>
              </div>
              <div class="t" style="text-decoration:line-through;">¥{{goods.priceLining}}</div>
          </div>
          <div class="btn">
              <div class="money flex fd-c ai-c jc-c">
                  <div class="money-nb">{{goods.price}}</div>
                  <div class="money-txt">到手价</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  components:{},
  props:["goods"],
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.mustBuyGoods{
    position: relative;
    width: 7.10rem;
    background: #FFFFFF;
    border-radius: .14rem;
    padding: .27rem 0 .4rem;
    margin-bottom: .25rem;
}
.goods-img{
    width: 2.94rem;
    height: 2.94rem;
    border-radius: .10rem;
    margin-left: .15rem;
    margin-right: .15rem;
}
.mustBuyGoods-r{
    width: 3.6rem;
}

.mustBuyGoods-r .name{
    width: 100%;
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 800;
    color: #282828;
}
.reduction{
    width: 3.42rem;
    height: 1.6rem;
    background-image: url(/img/reduction.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.reduction .t1{
    margin-top: .95rem;
}
.reduction .t{
    width: 1rem;
    text-align: center;
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #6E6E6E;
    white-space: nowrap;
}
.btn{
    width: 3.45rem;
    height: .64rem;
    background-image: url(/img/snapUp.png);
    background-size: 100% 100%;
    margin-top: .2rem;
}
.money {
    width: 50%;
    height: 100%;
}
.money .money-nb{
    font-size: .32rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FF3604;
    line-height: .34rem;
}
.money .money-nb::before{
    content: '¥';
    font-weight: 300;
    font-size: .22rem;
}
.money .money-txt{
    font-size: .20rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FF3604;
    line-height: .22rem;
}
.icon{
    position: absolute;
    left: -.05rem;
    top: -.05rem;
    width: .91rem;
    height: .97rem;
    z-index: 2;
}
</style>