<template>
    <div class="wrapper flex fd-c ai-c">
        <img class="banner" :src="$route.query.picture" alt="" />
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            class="list flex fd-c ai-c"
        >
                <template v-for="(item, index) in list">
                    <v-mustBuyGoods
                        :goods="item"
                        :isNumberHide="true"
                        :key="index"
                    />
                </template>
        </van-list>
    </div>
</template>

<script>
import mustBuyGoods from "@/components/mustBuyGoods";
import { goodsTag } from "../../../api";
export default {
    name: "mustBuyList",
    components: {
        "v-mustBuyGoods": mustBuyGoods,
    },
    data() {
        return {
            nav: [
                {
                    name: "正在团",
                },
                {
                    name: "已结束",
                },
            ],
            acitve: 0,
            fixed: false,
            opacity: 1,
            current: 1,
            list: [],
            loading: false,
            finished: false,
        };
    },
    watch: {},
    computed: {},
    methods: {
        fixedNav() {
            var offsetTop = this.$refs.box.offsetTop;
            var scrollTop = document.documentElement.scrollTop;
            this.opacity = (scrollTop - offsetTop) / 40;
            scrollTop >= offsetTop ? (this.fixed = true) : (this.fixed = false);
        },
        navClick(e) {
            console.log(e);
        },
        //今日必买
        goodsTag() {
            goodsTag({
                goodsTagId: 1,
                current: this.current,
                size: 20,
            }).then((res) => {
                var list = res.data.data.records;
                this.loading = false;
                this.current++;
                if (list.length == 0) {
                    this.finished = true;
                }
                for (var item of list) {
                    item.pic = item.pic ? item.pic.split(",")[0] : [];
                    this.list.push(item);
                }
            });
        },
        onLoad() {
            // 异步更新数据
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            this.goodsTag();
        },
    },
    created() {
    },
    mounted() {
        // window.addEventListener("scroll", this.fixedNav, false);
    },
    beforeDestroy() {
        // 在组件生命周期结束的时候销毁。
        // window.removeEventListener("scroll", this.fixedNav, false);
    },
};
</script>
<style scoped>
.wrapper {
    width: 100%;
    min-height: 100vh;
}
.banner {
    width: 100%;
    height: 1.37rem;
    object-fit: cover;
    margin-top: .1rem;
}
.fixed {
    position: fixed;
    top: 0;
    padding: 0 0.4rem !important;
    border-radius: 0 !important;
}
.box {
    width: 7.1rem;
    height: 0.74rem;
    margin-top: 0.18rem;
}
.navbox {
    top: 0;
    left: 0;
    z-index: 100;
    width: 6.7rem;
    height: 0.74rem;
    background: #ffffff;
    border-radius: 0.1rem;
    overflow: hidden;
    padding: 0 0.2rem;
}
.nav {
    width: 100%;
    height: 0.74rem;
    overflow-y: scroll;
}
.nav .nav-item {
    position: relative;
    width: 50%;
    text-align: center;
    font-size: 0.26rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    white-space: nowrap;
}
.nav .acitve {
    font-size: 0.3rem;
    font-weight: 800;
    color: #f96f30;
    margin-right: 0.4;
}
.nav .acitve::before {
    content: "";
    position: absolute;
    left: calc(50% - 0.58rem);
    bottom: -0.08rem;
    width: 1.16rem;
    height: 0.04rem;
    background: #f96f30;
    border-radius: 0.02rem;
}
.list {
    margin-top: 0.35rem;
}
.list .title {
    width: 2.54rem;
    background-image: url(/img/2020@2x.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    font-size: 0.34rem;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-bottom: 0.25rem;
}
</style>